<script lang="ts">
export default {
  name: 'LinkedAccountsSwitcher',
  compatConfig: { MODE: 3 },
};

export interface ILink {
  user_id: number;
  logo: string;
  name: string;
  company_name: string;
  domain: string;
  user_full_name: string;
}

export interface ICurrentUser {
  company_logo: string;
  company_name: string;
  domain: string;
  full_name: string;
}

interface ILinkResponse {
  links: ILink[];
  connect_new: string;
  sign_out_all: string;
  current_user: ICurrentUser;
}
</script>

<script setup lang="ts">
import { defineProps, ref, Ref, watch } from 'vue';
import LinkedAccountsSwitcherModal from '@/components/organisms/LinkedAccountsSwitcherModal/Index.vue';
import BaseButton from '@/components/atoms/BaseButton/Index.vue';
import { useTestHelpers } from '@/composables/useTestHelpers';
import { useApiClient } from '@/composables/useApiClient';
import { useI18n } from '@/composables/useI18n';
import { useElementVisibility } from '@vueuse/core';
import { LinkedAccountsSwitcher as LinkedAccountsSwitcherData } from '@/json_schema_types/sidebar_navigation';

const props = defineProps<{ switcherData: LinkedAccountsSwitcherData }>();

const { generateTestId } = useTestHelpers();
const { apiClient } = useApiClient();
const { t } = useI18n();

const linkedAccountsSwitcher = ref(null);
const modalOpen: Ref<boolean> = ref(false);
const targetIsVisible = useElementVisibility(linkedAccountsSwitcher);
const links: Ref<ILink[]> = ref([]);
const currentUser: Ref<ICurrentUser | undefined> = ref();
const signOutPath: Ref<string> = ref('');
const connectNewPath: Ref<string> = ref('');
const loading: Ref<boolean> = ref(false);
const fetchedLinks: Ref<boolean> = ref(false);

const switchAccount = async (link) => {
  try {
    loading.value = true;
    const response = await apiClient.post(props.switcherData.switch_path, {
      user_id: link.user_id,
    });
    window.location.href = response.data.redirect_url;
  } catch (error) {
    console.error(error);
  }
};
const addNewLink = () => {
  modalOpen.value = true;
};
const fetchLinks = async () => {
  if (!props.switcherData.endpoint || fetchedLinks.value) {
    return;
  }
  try {
    loading.value = true;
    const response: { data: ILinkResponse } = await apiClient.get(props.switcherData.endpoint);
    links.value = response.data.links;
    currentUser.value = response.data.current_user;
    signOutPath.value = response.data.sign_out_all;
    connectNewPath.value = response.data.connect_new;
    fetchedLinks.value = true;
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};

watch(
  () => targetIsVisible.value,
  (isVisible) => {
    if (isVisible) {
      fetchLinks();
    }
  }
);
</script>

<template>
  <div ref="linkedAccountsSwitcher" class="linked-accounts-switcher">
    <div v-if="loading" class="skeleton-loading circle" />
    <template v-else>
      <BaseButton
        v-for="link in links"
        :key="link.user_id"
        v-tippy="{ content: `${link.name}` }"
        variant="icon"
        theme="brand"
        :data-test-id="generateTestId('linked account switcher switch to', link.name)"
        @click.prevent="switchAccount(link)"
        @keyup.enter="switchAccount(link)"
      >
        <img :src="link.logo" class="linked-accounts-switcher__company-image" alt="" />
        <span class="visually-hidden">{{
          t('vue_templates.linked_accounts_switcher.switch_to', {
            name: link.name,
          })
        }}</span>
      </BaseButton>
    </template>
    <BaseButton
      v-if="links.length < 5"
      v-tippy="{ content: `${t('vue_templates.linked_accounts_switcher.add_another')}` }"
      variant="icon"
      fa-prepend-icon="fa-circle-plus fa-regular"
      fa-icon-size="xl"
      theme="brand"
      :data-test-id="generateTestId('linked account switcher add new button')"
      @click.prevent="addNewLink"
      @keyup.enter="addNewLink"
      ><span class="visually-hidden">{{
        t('vue_templates.linked_accounts_switcher.add_another')
      }}</span>
    </BaseButton>

    <LinkedAccountsSwitcherModal
      v-model:model-value="modalOpen"
      :switch-path="switcherData.switch_path"
      :unlink-path="switcherData.unlink_path"
      :links="links"
      :current-user="currentUser"
      :sign-out-path="signOutPath"
      :connect-new-path="connectNewPath"
      @update:model-value="modalOpen = $event"
    />
  </div>
</template>

<style lang="scss" scoped>
.linked-accounts-switcher {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;

  .linked-accounts-switcher__company-image {
    max-width: 2.5rem;
    max-height: 2.5rem;
    border-radius: 50%;
    border: 1px solid $cool-grey;
  }

  .skeleton-loading {
    width: 2.5rem;
    height: 2.5rem;
  }
}
</style>
