<script lang="ts">
export default {
  name: 'TileSelector',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { computed, defineProps } from 'vue';
import { IBaseTileResponse } from '@/composables/useTileProps';
import ItemTile from '@/components/organisms/Tiles/ItemTile.vue';
import EventSessionTile from '@/components/organisms/Tiles/EventSessionTile/Index.vue';
import EventTile from '@/components/organisms/Tiles/EventTile/Index.vue';
import LearnListTile from '@/components/organisms/Tiles/LearnlistTile/Index.vue';
import SurveyTile from '@/components/organisms/Tiles/SurveyTile/Index.vue';
import QuizTile from '@/components/organisms/Tiles/QuizTile/Index.vue';
import ChannelTile from '@/components/organisms/Tiles/ChannelTile/Index.vue';
import OneToOneTile from '@/components/organisms/Tiles/OneToOneTile/Index.vue';
import { TileType } from '@/types/Tiles';

const props = defineProps<{
  json: IBaseTileResponse;
  locked?: boolean;
  lockedUntilTasked?: boolean;
}>();

const tileType = computed(() => {
  switch (props.json.tile_type) {
    case TileType.CAROUSEL:
      return ChannelTile;
    case TileType.LEARNLIST:
      return LearnListTile;
    case TileType.ITEM:
      return ItemTile;
    case TileType.EVENT_SESSION:
      return EventSessionTile;
    case TileType.EVENT:
      if (props.json.event) {
        return EventSessionTile;
      }
      return EventTile;
    case TileType.SURVEY:
      return SurveyTile;
    case TileType.QUIZ:
      return QuizTile;
    case TileType.ONE_TO_ONE:
      return OneToOneTile;
    default:
      return ItemTile;
  }
});
</script>

<template>
  <component
    :is="tileType"
    data-test-id="tile-selector"
    :type="json.tile_type"
    :json="(json as IBaseTileResponse)"
    :loading="false"
    :locked="locked"
    :locked-until-tasked="lockedUntilTasked"
  />
</template>

<style lang="scss" scoped>
.tile-selector {
  color: inherit;
}
</style>
